@import "../_vars"

.judgeBox, .judgeBoxActive
  border: 2px solid #444
  display: inline-block
  padding: 8px
  margin: 5px 5px

.judgeBoxActive
    border-color: rgb(177, 212, 169)!important
