@import "../_vars.sass"
#mainContainer
  .blockMenu
    //float: right
    //margin-top: -62px
    button.rightmost
      margin-right: 0 !important

  .blockContainer
    vertical-align: top

    &.Video, &.Document
      &:not(.editMode)
        display: inline-block

    &.Video
      @media all and (min-width: 440px)
        padding-right: 10px
      padding-bottom: 7px

      img, iframe
        max-width: 100%
        @media all and (max-width: 440px)
          height: 240px
        @media all and (max-width: 380px)
          height: 200px

      div.playbutton
        position: absolute
        margin-top: -170px
        text-align: center
        width: 100%
        border: 2px solid red
        font-size: 60px
    //margin-left: 200px

    &.Document a
      display: block
      text-align: center
      width: 180px
      padding: 10px
      padding-left: 0
      margin-left: 0px
      border-bottom: none !important

      div
        word-break: break-word
    &.Link
      a.maxbtn
        display: inline-block
        margin-top: 5px!important
        margin-bottom: 5px!important

    &.Gallery
      a, a:link
        border-bottom: none!important
      img
        margin-right: 5px

    &.editMode:not(:last-of-type)
      border-bottom: 2px solid $tvg
      margin-bottom: 20px

    .blockMover
      float: right
      cursor: pointer

      svg
        margin-left: 5px

  #medieninhalte

    .Video
      display: block !important

div.MuiPaper-root
  iframe
    max-width: 100%
    @media all and (max-width: 400px)
      height: 240px


